export const fetchAjaxFormSender = () =>
import(
    '../modules/ajax-form-sender'
    /* webpackChunkName: "ajax-form-sender" */
);

export const fetchValidator = () =>
import(
    '../modules/validator'
    /* webpackChunkName: "validator" */
);